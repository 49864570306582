import React from 'react'
import styled from 'styled-components';

const StyledWrapper = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 3.1rem;
  display: flex;
  margin: 0.3rem 0;
  padding: 0.4rem;
  align-items: center;
  padding: 0.8rem 1rem 0.7rem 1rem;
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding-right: 0.5rem;
    img {
      height: 1.8rem;
      width: auto;
    }
  }
`

const InputElement = styled.input`
  border: none;
  outline: none;
  background-color: transparent;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 600;
  color: white;
  padding-left: 0.75rem;
  &::placeholder {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
  &:focus {
      outline: none;
      background-color: rgba(255, 255, 255, 0.02);
  }
`

const TextareaElement = styled(InputElement)`
    min-height: 5rem;
`

const InputIcon = (props) => {
  if(props.element === 'input') {
    return (
      <StyledWrapper>
        <div className='icon-wrapper'>
          <img src={props.icon} alt="icon input" />
        </div>
        <InputElement
          onChange={props.changed}
          placeholder={props.placeholder}
          required={props.required}
          type={props.type}
          value={props.value}
          min={props.min}
          max={props.max}
          step="any"
        />
      </StyledWrapper>
    
    )
  }

  if(props.element === 'textarea') {
    return (
        <TextareaElement
          as="textarea"
          onChange={props.changed}
          placeholder={props.placeholder}
          required={props.required}
        />
    )
  }
}

export default InputIcon;